.foot-container {
    /* height: 280px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #18181b;
    justify-content: start;
    align-items: center;
    color: gray;
    padding-top: 30px;
    padding-bottom: 30px;
    /* min-width: 680px; */
    /* justify-content: space-between;
    align-items: start;     */
}