.home-download-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: 35px;
    margin-bottom: 35px;
    /* min-width: 680px; */
}