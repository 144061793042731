
.button {
    width: 200px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: none;
    color: black;
    border: none;
    border-radius: 50px;
    cursor: pointer;
}

.button:hover {
    background-color: #ddd;
} 