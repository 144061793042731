.download-main-container {
    width: 100%;
    /* height: 550px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efede7;
    padding-top: 45px;
    padding-bottom: 45px;
}

.download-button:hover {
    background-color: red;
    color: white;
}