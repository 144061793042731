.theme-container {
    width: 100%;
    /* min-width: 680px; */
    /* height: 700px; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding-top: 35px;
    padding-bottom: 35px;
}

@media screen and (max-width: 905px) {
    .shield-icon {
        display: none;
    }

}