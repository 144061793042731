.all-apps-container {
    width: 100%;
    /* height: 400px; */
    background-color: #efede7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;
}