.why-intro-container {
    /* height: 360px; */
    background-color: #efede7;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;
}