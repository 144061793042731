.intro-container {
    /* height: 500px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #18181b;
    color: white;
    /* min-width: 680px; */
}