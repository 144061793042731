.nav-menu {
    /* color: brown; */
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: white; /* Change color as needed */
    transition: height 0.3s ease; /* Optional: Add transition effect */
}

.underline-text:hover .underline {
    height: 100%;
}

.bars {
    display: none;
  }

@media screen and (max-width: 905px) {
    .nav-menu {
        display: none;
    }

    .bars {
        display: block;
    }
}
